<template>
  <div :style="{ width: width || '100%' }" class="public-form">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      :disabled="formConfig.disabled || false"
      :label-width="formConfig.labelWidth || '140px'"
      class="public-form"
    >
      <div v-for="(row, index) in formConfig.items" :key="index">
        <div
          v-for="(item, i) in row"
          :key="i"
          :style="{
            width: !showItem(formData, item.show)
              ? 0
              : item.itemWidth || '33.33%',
          }"
        >
          <div class="form-item-box" v-if="showItem(formData, item.show)">
            <el-form-item
              :prop="item.prop || item.key"
              :label-width="item.labelWidth"
              :label="item.label"
            >
              <!-- 输入框 -->
              <div
                class="drawer-content-title"
                v-if="item.valueType === 'text'"
              >
                {{ item.text }}
              </div>
              <div  v-if="item.valueType === 'look'">
                 <button  type="button" :disabled="false" class="look_btn"
                @click="
                  item.fn &&item.fn(formData[item.key], formData)"
              >
                查看合同列表
              </button>
              </div>

              <div
                v-if="item.valueType === 'iconInput'"
                :class="[
                  'button-input',
                  itemDisabled(formData, item.disabled || formConfig.disabled)
                    ? 'isDisabled'
                    : '',
                ]"
                @click="
                  item.fn &&
                  !itemDisabled(formData, item.disabled || formConfig.disabled)
                    ? item.fn(formData[item.key], formData)
                    : ''
                "
              >
                <span>{{ formData[item.key] }}</span>
                <i
                  :class="['el-input__icon', item.icon]"
                  @click="
                    item.iconClick
                      ? item.iconClick(formData[item.key], formData)
                      : ''
                  "
                ></i>
              </div>

              <!-- input 输入框-->
              <el-input
                :style="{ width: item.inputWidth }"
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                @input="inputValidation(item.enableInput, item.key)"
                :maxlength="item.maxlength || 50"
                v-if="!item.valueType || item.valueType === 'input'"
                :type="item.inputType || 'text'"
                v-model="formData[item.key]"
                :disabled="itemDisabled(formData, item.disabled)"
                :placeholder="item.placeholder || '请输入' + item.label"
              >
                <i
                  slot="suffix"
                  :class="['el-input__icon', item.icon]"
                  @click="
                    item.iconClick
                      ? item.iconClick(formData[item.key], formData)
                      : ''
                  "
                ></i>
              </el-input>

              <!-- textarea 多行输入框-->
              <el-input
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                :maxlength="item.maxlength || 200"
                :rows="item.rows || 3"
                :style="{ width: item.itemWidth ? item.itemWidth : '100%' }"
                v-if="item.valueType === 'textarea'"
                type="textarea"
                :autosize="false || item.autosize"
                v-model="formData[item.key]"
                :disabled="itemDisabled(formData, item.disabled)"
                :placeholder="item.placeholder || '请输入' + item.label"
              ></el-input>

              <!-- select 下拉框 -->
              <el-select
                :multiple="item.multiple || false"
                clearable
                :ref="'select' + i"
                :disabled="itemDisabled(formData, item.disabled)"
                @change="
                  item.fn
                    ? item.fn(
                        formData[item.key],
                        formData,
                        $refs['select' + i],
                        item.data
                      )
                    : ''
                "
                v-if="item.valueType === 'select'"
                v-model="formData[item.key]"
                :placeholder="item.placeholder || '请选择' + item.label"
              >
                <!-- :disabled="option.disabled" -->
                <el-option
                  v-for="(option, index) in item.data"
                  :key="index"
                  :disabled="
                    item.options
                      ? isDisabled(option, item.options['disabled'])
                      : false
                  "
                  :label="
                    item.options && item.options.label
                      ? option[item.options['label']]
                      : option.dictValue
                  "
                  :value="
                    item.options && item.options.value
                      ? option[item.options['value']]
                      : option.dictCode
                  "
                ></el-option>
              </el-select>

              <!-- 复杂框 -->
              <div class="connect-box" v-if="item.valueType === 'complex'">
                <div
                  v-for="(j, index) in item.component"
                  :key="index"
                  :style="{ width: j.width }"
                >
                  <el-select
                    :multiple="item.multiple || false"
                    clearable
                    :disabled="itemDisabled(formData, item.disabled)"
                    v-if="j.valueType === 'select'"
                    v-model="formData[j.key]"
                    @change="j.fn ? j.fn(formData[item.key[0]], formData) : ''"
                    :placeholder="item.placeholder || '请选择' + item.label"
                  >
                    <el-option
                      v-for="(option, index) in j.data"
                      :key="index"
                      :disabled="
                        j.options
                          ? isDisabled(option, j.options['disabled'])
                          : false
                      "
                      :label="
                        j.options
                          ? option[j.options['label']]
                          : option.dictValue
                      "
                      :value="
                        j.options ? option[j.options['value']] : option.dictCode
                      "
                    ></el-option>
                  </el-select>
                  <span v-if="j.connect">{{ j.connect }}</span>
                  <el-input
                    v-if="!j.valueType || j.valueType === 'input'"
                    v-model="formData[j.key]"
                    :disabled="itemDisabled(formData, item.disabled)"
                    type="number"
                    placeholder="请输入"
                  ></el-input>
                </div>
              </div>

              <!-- switch 选择器 -->
              <el-switch
                :disabled="itemDisabled(formData, item.disabled)"
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                v-if="item.valueType === 'switch'"
                v-model="formData[item.key]"
              ></el-switch>

              <!--时间日期范围选择器-->
              <el-date-picker
                :disabled="itemDisabled(formData, item.disabled)"
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                v-if="item.valueType === 'datetimerange'"
                v-model="formData[item.key]"
                type="datetimerange"
                value-format="timestamp"
                range-separator="至"
                :start-placeholder="
                  initPlaceholder(item.placeholder, 0) || '开始日期'
                "
                :end-placeholder="
                  initPlaceholder(item.placeholder, 1) || '结束'
                "
              >
              </el-date-picker>

              <!--日期选择器-->
              <el-date-picker
                :disabled="itemDisabled(formData, item.disabled)"
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                v-if="item.valueType === 'date'"
                v-model="formData[item.key]"
                type="date"
                value-format="yyyy-MM-dd"
                :placeholder="item.placeholder || '请选择日期'"
              >
              </el-date-picker>

              <!--日期时间选择器-->
              <el-date-picker
                :disabled="itemDisabled(formData, item.disabled)"
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                v-if="item.valueType === 'datetime'"
                v-model="formData[item.key]"
                value-format="timestamp"
                type="datetime"
                :placeholder="item.placeholder || '请选择时间'"
              >
              </el-date-picker>

              <!--日期范围选择器-->
              <el-date-picker
                :disabled="itemDisabled(formData, item.disabled)"
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                class="date-range-box"
                v-if="item.valueType === 'daterange'"
                v-model="formData[item.key]"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                :start-placeholder="
                  initPlaceholder(i.placeholder, 0) || '开始日期'
                "
                :end-placeholder="initPlaceholder(i.placeholder, 1) || '结束'"
              >
              </el-date-picker>

              <!--时间范围选择器-->
              <el-time-picker
                v-if="item.valueType === 'timerange'"
                is-range
                v-model="formData[item.key]"
                range-separator="至"
                value-format="HH:mm:ss"
                :start-placeholder="
                  initPlaceholder(i.placeholder, 0) || '开始时间'
                "
                :end-placeholder="
                  initPlaceholder(i.placeholder, 1) || '结束时间'
                "
                placeholder="选择时间范围"
              >
              </el-time-picker>

              <!--月份选择器-->
              <el-date-picker
                v-if="item.valueType === 'month'"
                v-model="formData[item.key]"
                type="month"
                :picker-options="item.pickerOptions || {}"
                value-format="yyyy-MM"
                placeholder="选择月"
              >
              </el-date-picker>

              <!-- checkbox 多选框 -->
              <el-checkbox-group
                :disabled="itemDisabled(formData, item.disabled)"
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                v-if="item.valueType === 'checkbox'"
                v-model="formData[item.key]"
              >
                <el-checkbox
                  v-for="(chenck, index) in item.data"
                  :key="index"
                  :label="chenck[item.options['value']] || chenck.value"
                >
                  {{ chenck[item.options["label"]] || chenck.label }}
                </el-checkbox>
              </el-checkbox-group>

              <!-- radio 单选框 -->
              <el-radio-group
                :disabled="itemDisabled(formData, item.disabled)"
                @change="item.fn ? item.fn(formData[item.key], formData) : ''"
                v-if="item.valueType === 'radio'"
                v-model="formData[item.key]"
              >
                <el-radio
                  v-for="(chenck, index) in item.data"
                  :key="index"
                  :label="chenck[item.options['value']] || chenck.value"
                >
                  {{ chenck[item.options["label"]] || chenck.label }}
                </el-radio>
              </el-radio-group>

              <!-- 上传资源（图片, 视频等） -->
              <div class="upload-container" v-if="item.valueType === 'upload'">
                <div class="upload-box">
                  <!-- 图片回显 -->
                  <div
                    v-if="
                      $route.query.disabled && formData[item.key].length === 0
                    "
                    class="show-Img-box"
                    style="width: 190px; height: 130px"
                  >
                    <div class="img-box" style="height: 100%">
                      <div class="button-box2" style="line-height: 110px">
                        <i class="icon el-icon-plus"></i>
                      </div>
                    </div>
                  </div>
                  <div
                    class="show-Img-box"
                    v-for="(itemFile, index) in formData[item.key]"
                    :key="index"
                    :style="{
                      width:
                        item.otherConfig && item.otherConfig.imgWidth
                          ? item.otherConfig.imgWidth
                          : '190px',
                    }"
                  >
                    <div class="img-box bgBlack">
                      <div
                        v-if="
                          item.otherConfig &&
                          !formConfig.disabled &&
                          !itemDisabled(formData, item.disabled) &&
                          item.otherConfig.method &&
                          !item.otherConfig.method.includes('big')
                            ? true
                            : false
                        "
                        class="button-box"
                        :style="{
                          lineHeight:
                            item.otherConfig && item.otherConfig.imgHeight
                              ? item.otherConfig.imgHeight
                              : '130px',
                        }"
                      >
                        <input
                          style="display: none"
                          class="formUPLoad"
                          type="file"
                          @change="
                            (uploadType = 'list'),
                              uploadVerification(
                                $event,
                                item.uploadConfig,
                                item.key
                              );
                          "
                          :accept="
                            item.uploadConfig ? item.uploadConfig.accept : ''
                          "
                        />
                        <i
                          v-if="
                            item.otherConfig &&
                            !formConfig.disabled &&
                            !itemDisabled(formData, item.disabled) &&
                            item.otherConfig.method
                              ? item.otherConfig.method.includes('edit')
                              : false
                          "
                          @click="editUpLoad(index, $event)"
                          class="icon el-icon-edit"
                        ></i>
                        <i
                          v-if="
                            item.otherConfig &&
                            !formConfig.disabled &&
                            !itemDisabled(formData, item.disabled) &&
                            item.otherConfig.method
                              ? item.otherConfig.method.includes('delete')
                              : false
                          "
                          @click="
                            (uploadType = 'list'), deleteFile(index, item.key);
                          "
                          class="icon el-icon-delete"
                        ></i>
                      </div>

                      <!-- <video v-if="showResources(item.uploadConfig)"-->
                      <!-- :src="item.options ? itemFile[item.options.fileUrlKey] : itemFile.materialUrl"-->
                      <!-- style="width: 100%;"-->
                      <!-- :style="{height: item.otherConfig && item.otherConfig.imgHeight ? item.otherConfig.imgHeight : '130px'}"></video>-->

                      <div
                        v-if="itemFile.materialType == 'audio'"
                        class=""
                        style="
                          word-wrap: break-word;
                          word-break: normal;
                          width: 100%;
                          height: 110px;
                          padding-top: 40px;
                          background: #fff;
                          text-align: center;
                        "
                      >
                        <span>{{ itemFile.keyName }}</span>
                        <p>
                          <i
                            class="el-icon-service"
                            style="font-weight: 900; font-size: 30px"
                          ></i>
                        </p>
                      </div>

                      <img
                        v-else
                        :src="
                          item.options && itemFile[item.options.fileUrlKey]
                            ? itemFile[item.options.fileUrlKey]
                            : itemFile.materialUrl
                        "
                        @mousemove="
                          showImgDig(
                            item.options
                              ? itemFile[item.options.fileUrlKey]
                              : itemFile.materialUrl,
                            item.otherConfig
                          )
                        "
                        alt=""
                        :style="{
                          height:
                            item.otherConfig && item.otherConfig.imgHeight
                              ? item.otherConfig.imgHeight
                              : '130px',
                        }"
                      />
                    </div>

                    <el-input
                      type="text"
                      v-if="
                        item.otherConfig
                          ? item.otherConfig.showInfoInput
                          : false
                      "
                      :maxLength="item.otherConfig.inputLength || 30"
                      :placeholder="
                        item.otherConfig
                          ? item.otherConfig.infoInputPlaceholder
                          : '请输入描述信息'
                      "
                      v-model="
                        itemFile[
                          item.options && item.options.fileInfoKey
                            ? item.options.fileInfoKey
                            : 'description'
                        ]
                      "
                    ></el-input>
                  </div>

                  <!-- 上传 -->
                  <div
                    class="item-upload-box"
                    v-if="
                      showUpload(
                        item.uploadConfig.count,
                        formData[item.key].length,
                        item.disabled
                      ) && !itemDisabled(formData, item.disabled)
                    "
                    :style="{
                      width:
                        item.otherConfig && item.otherConfig.imgWidth
                          ? item.otherConfig.imgWidth
                          : '190px',
                    }"
                  >
                    <div
                      :style="{
                        height:
                          item.otherConfig && item.otherConfig.imgHeight
                            ? item.otherConfig.imgHeight
                            : '130px',
                      }"
                    >
                      <i class="icon el-icon-plus"></i>
                      <input
                        type="file"
                        @change="
                          (uploadType = 'list'),
                            uploadVerification(
                              $event,
                              item.uploadConfig,
                              item.key
                            );
                        "
                        :accept="
                          item.uploadConfig ? item.uploadConfig.accept : ''
                        "
                      />
                    </div>
                    <!-- <span v-if="item.otherConfig.uploadHint">{{item.otherConfig.uploadHint}}</span>-->
                  </div>
                </div>
                <span
                  class="upload-hint"
                  v-if="
                    (item.otherConfig ? item.otherConfig.uploadHint : false) &&
                    !formConfig.disabled &&
                    !itemDisabled(formData, item.disabled)
                  "
                >
                  {{ item.otherConfig.uploadHint }}</span
                >
              </div>

              <!-- 单个资源上传 -->
              <div
                class="upload-container"
                v-if="item.valueType === 'singleUpload'"
              >
                <div class="upload-box">
                  <!-- 图片回显 -->
                  <div
                    v-if="
                      $route.query.disabled && formData[item.key].length == 0
                    "
                    class="show-Img-box"
                    style="width: 190px; height: 130px"
                  >
                    <div class="img-box" style="height: 100%">
                      <div class="button-box2" style="line-height: 110px">
                        <i class="icon el-icon-plus"></i>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="formData[item.key]"
                    class="show-Img-box"
                    :key="index"
                    :style="{
                      width:
                        item.otherConfig && item.otherConfig.imgWidth
                          ? item.otherConfig.imgWidth
                          : '190px',
                    }"
                  >
                    <div class="img-box bgBlack">
                      <!-- 蒙版-->
                      <div
                        v-if="
                          item.otherConfig &&
                          item.otherConfig.method &&
                          !formConfig.disabled &&
                          !itemDisabled(formData, item.disabled) &&
                          !item.otherConfig.method.includes('big')
                            ? true
                            : false
                        "
                        class="button-box"
                        :style="{
                          lineHeight:
                            item.otherConfig && item.otherConfig.imgHeight
                              ? item.otherConfig.imgHeight
                              : '150px',
                        }"
                      >
                        <input
                          style="display: none"
                          class="formUPLoad"
                          type="file"
                          @change="
                            (uploadType = 'single'),
                              uploadVerification(
                                $event,
                                item.uploadConfig,
                                item.key
                              );
                          "
                          :accept="
                            item.uploadConfig ? item.uploadConfig.accept : ''
                          "
                        />
                        <i
                          v-if="
                            item.otherConfig &&
                            !formConfig.disabled &&
                            !itemDisabled(formData, item.disabled) &&
                            item.otherConfig.method
                              ? item.otherConfig.method.includes('delete')
                              : false
                          "
                          @click="editUpLoad(index, $event)"
                          class="icon el-icon-edit"
                        ></i>
                        <i
                          v-if="
                            item.otherConfig &&
                            !formConfig.disabled &&
                            !itemDisabled(formData, item.disabled) &&
                            item.otherConfig.method
                              ? item.otherConfig.method.includes('delete')
                              : false
                          "
                          @click="
                            (uploadType = 'single'),
                              deleteFile(index, item.key);
                          "
                          class="icon el-icon-delete"
                        ></i>
                      </div>

                      <!-- <video v-if="showResources(item.uploadConfig)"-->
                      <!-- :src="item.options ? formData[item.options.fileUrlKey] : formData.materialUrl"-->
                      <!-- :style="{height: item.otherConfig && item.otherConfig.imgHeight ? item.otherConfig.imgHeight : '150px'}"></video>-->
                      <img
                        :src="
                          item.options && formData[item.options.fileUrlKey]
                            ? formData[item.options.fileUrlKey]
                            : formData.materialUrl
                        "
                        @mousemove="
                          showImgDig(
                            item.options
                              ? formData[item.options.fileUrlKey]
                              : formData.materialUrl,
                            item.otherConfig
                          )
                        "
                        alt=""
                        :style="{
                          height:
                            item.otherConfig && item.otherConfig.imgHeight
                              ? item.otherConfig.imgHeight
                              : '150px',
                        }"
                      />
                    </div>
                  </div>
                  <!-- 上传 -->
                  <div
                    class="item-upload-box"
                    v-if="!formData[item.key]"
                    :style="{
                      width:
                        item.otherConfig && item.otherConfig.imgWidth
                          ? item.otherConfig.imgWidth
                          : '190px',
                    }"
                  >
                    <div
                      :style="{
                        height:
                          item.otherConfig && item.otherConfig.imgHeight
                            ? item.otherConfig.imgHeight
                            : '150px',
                      }"
                    >
                      <i class="icon el-icon-plus"></i>
                      <input
                        type="file"
                        @change="
                          (uploadType = 'single'),
                            (uploadOption = item.options),
                            uploadVerification(
                              $event,
                              item.uploadConfig,
                              item.key
                            );
                        "
                        :accept="
                          item.uploadConfig ? item.uploadConfig.accept : ''
                        "
                      />
                    </div>
                    <!-- <span v-if="item.otherConfig.uploadHint">{{item.otherConfig.uploadHint}}</span>-->
                  </div>
                </div>
                <span
                  class="upload-hint"
                  v-if="
                    (item.otherConfig ? item.otherConfig.uploadHint : false) &&
                    !formConfig.disabled &&
                    !itemDisabled(formData, item.disabled)
                  "
                >
                  {{ item.otherConfig.uploadHint }}</span
                >
              </div>

              <!-- 文件上传-->
              <div class="upload-input" v-if="item.valueType === 'inputUpload'">
                <div
                  v-if="
                    !itemDisabled(
                      formData,
                      item.disabled || formConfig.disabled
                    )
                  "
                >
                  <el-button type="primary" size="mini">上传</el-button>
                  <input
                    @change="
                      (uploadType = 'list'),
                        uploadVerification(
                          $event,
                          item.uploadConfig,
                          item.key,
                          index
                        );
                    "
                    type="file"
                    :accept="item.uploadConfig ? item.uploadConfig.accept : ''"
                  />
                  <div v-if="item.uploadConfig">
                    请上传: {{ item.uploadConfig.accept }}等类型
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    style=""
                    v-for="(fileItem, index) in formData[item.key]"
                    :key="index"
                  >
                    <div
                      class="image-box"
                      v-if="
                        fileItem.fileName.indexOf('jpg') !== -1 ||
                        fileItem.fileName.indexOf('png') !== -1
                      "
                    >
                      <img
                        style="width: 50px; height: 50px"
                        :src="fileItem.url"
                        alt=""
                      />
                      <div class="image-box-button">
                        <icon
                          @click="
                            imageConfig = fileItem;
                            imageShow = true;
                          "
                          class="el-icon-view"
                          style="color: #fff; margin-right: 10px"
                        ></icon>
                        <icon
                          v-if="
                            !itemDisabled(
                              formData,
                              item.disabled || formConfig.disabled
                            )
                          "
                          @click="deleteFile(index, item.key)"
                          class="el-icon-close"
                          style="color: #fff"
                        ></icon>
                      </div>
                    </div>
                  </div>
                  <div
                    class="upload-input-button"
                    style="width: 100%"
                    v-for="(fileItem, index) in formData[item.key]"
                    :key="fileItem.urlPath"
                  >
                    <div
                      v-if="
                        fileItem.fileName.indexOf('jpg') === -1 &&
                        fileItem.fileName.indexOf('png') === -1
                      "
                    >
                      <span>{{ fileItem.fileName }}</span>
                      <p>
                        <a
                          class="el-icon-download"
                          :href="`/upload/downloadController/download?objectName=${
                            fileItem.objectName ||
                            (
                              (fileItem.url
                                ? fileItem.url
                                : fileItem.filePath) || ''
                            )
                              .split('/')
                              .splice(-3)
                              .join('/')
                          }`"
                          :download="fileItem.fileName"
                        ></a>
                        <i
                          class="el-icon-close"
                          v-if="
                            !itemDisabled(
                              formData,
                              item.disabled || formConfig.disabled
                            )
                          "
                          @click="deleteFile(index, item.key)"
                        ></i>
                      </p>
                    </div>
                  </div>
                  <div v-if="uploadLoading" class="upload-loading-box"></div>
                </div>
              </div>

              <!-- 标点地图 -->
              <el-input
                class="input-location-box"
                v-if="item.valueType === 'inputMap'"
                :id="item.id"
                v-model="formData[item.key]"
                :placeholder="item.placeholder || '请输入' + item.label"
                type="text"
              >
                <el-button
                  slot="append"
                  icon="el-icon-location-outline"
                  @click="showMapFn(item)"
                ></el-button>
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>

      <!-- 表单按钮 -->
      <div
        :style="{ marginLeft: buttonConfig.left || '100px' }"
        v-if="showFormButton()"
      >
        <div
          style="display: inline-block; margin-right: 10px"
          v-for="(i, index) in buttonConfig.buttons"
          :key="index"
        >
          <el-button
            v-if="i.type === 'save' && i.show !== false"
            :disabled="i.disabled || false"
            :loading="i.loading"
            type="primary"
            @click="beforeSubmit(i.fn, 'formData', i)"
            >保存
          </el-button>
          <el-button
            v-if="i.type === 'saveDrafts' && i.show !== false"
            :disabled="i.disabled || false"
            :loading="i.loading"
            type="primary"
            @click="i.fn ? i.fn(formData, i) : ''"
            >保存至草稿箱
          </el-button>
          <el-button
            v-if="i.type === 'reset' && i.show !== false"
            :disabled="i.disabled || false"
            :loading="i.loading"
            @click="formsForEach('formData', i.fn)"
            >重置
          </el-button>
          <el-button
            v-if="i.type === 'back' && i.show !== false"
            :disabled="i.disabled || false"
            :loading="i.loading"
            @click="goBack(i, 'formData')"
            >返回
          </el-button>
        </div>
      </div>
    </el-form>

    <el-dialog
      title="位置"
      :visible.sync="mapConfig.mapDialogShow"
      class="map-dialog"
      @close="mapConfig.mapInit = true"
      style="height: 800px"
    >
      <div class="map-dialog-container">
        <div class="map-box">
          <el-input
            placeholder="请输入内容"
            v-model="mapConfig.siteName"
            @keyup.enter.native="searchSiteName()"
            style="position: absolute; top: 10px; left: 10px; z-index: 1"
          >
            <i
              slot="append"
              @click="searchSiteName()"
              class="el-icon-search"
            ></i>
          </el-input>
          <div id="map-container" style="width: 100%; height: 400px"></div>
        </div>
        <div class="location-box">
          <span>位置坐标:</span>
          <p>
            <input v-model="mapConfig.position[0]" type="number" /> -
            <input type="number" v-model="mapConfig.position[1]" />
          </p>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 20px"
            @click="searchPosition(true)"
            >标注</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 20px"
            @click="saveSite()"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="imageConfig.fileName"
      :append-to-body="true"
      :visible.sync="imageShow"
      @close="imageShow = false"
    >
      <img :src="imageConfig.url" style="width: 100%" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import AMap from '@amap/amap-jsapi-loader';
import API from '@/src/views/dms/utils/component_serve_api';
import formConfig from '@/found/components/formDesign/properties/formConfig';

const publicVar = {};
export default {
  name: 'edit-form',
  props: [
    'width',
    'rules',
    'ruleForm',
    'formConfig',
    'buttonConfig',
    'refName',
  ],
  data() {
    return {
      imageConfig: {},
      imageShow: false,
      mapConfig: {
        saveCallBack: '',
        map: '', // 地图实例
        mapDialogShow: false, // 地推显示
        mapInit: true, // 是否初始化map
        addressLocation: '', // 是否需要返回坐标
        siteName: '', // 地图查询名称
        position: [], // 当前坐标
        key: '', // 保存的值的key
      },
      uploadLoading: false,
      uploadType: 'list', // list: 上传列表 single: 单个
      uploadOption: {}, // 接收参数

      OYXInput: {}, // 记录验证通过的数据
    };
  },

  computed: {
    formData: {
      // 表单配置
      get() {
        return this.ruleForm;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  watch: {
    formConfig: {
      handler(val) {
        val.items[0].map((item) => {
          if (item.valueType === 'select' && item.options?.disabledNames) {
            item.data = this.selectOptionsDisabled(item, item.data || []);
          }
          return val;
        });
      },
      deep: true,
    },
  },
  created() {
    // this.formConfig.disabled = !!this.$route.query.disabled;
    this.initSelectData();
  },
  updated() {
    if (this.mapConfig.mapDialogShow && this.mapConfig.mapInit) {
      this.initMap();
      if (this.mapConfig.siteName) {
        this.searchSiteName();
      }
      this.mapConfig.mapInit = false;
    }
  },
  methods: {
    /* ------------渲染规则验证--------------*/
    show(formItemName) {
      // eslint-disable-next-line array-callback-return
      this.formConfig.item[0].map((i) => {
        if (i.key === formItemName) {
          i.show = true;
          return i;
        }
      });
    },
    // 初始化的数据
    initSelectData() {
      for (const i of this.formConfig.items) {
        i.forEach(async (k) => {
          if (k.valueType === 'complex') {
            for (const j of k.component) {
              // eslint-disable-next-line no-await-in-loop
              await this.setSelect(j);
            }
          } else {
            await this.setSelect(k);
          }
          if (k.valueType === 'input') {
            this.OYXInput[k.key] = this.formData[k.key]
              ? String(this.formData[k.key]).length
              : 0;
          }
        });
      }
    },

    async setSelect(k) {
      const valueTypes = [
        'select',
        'checkbox',
        'radio',
        'fixedLabel',
        'select,input',
      ];
      if (valueTypes.includes[k.valueType] !== -1 && k.path && !k.data) {
        const qs = require('qs');
        const params = qs.parse(k.path.split('?')[1]);
        const urlName = k.path.indexOf('?') !== -1
          ? k.path.slice(0, k.path.indexOf('?'))
          : k.path;
        // eslint-disable-next-line no-await-in-loop
        const { result } = await this.$http.post(
          API[urlName] || k.path,
          params,
        );
        if (result.constructor === Array) {
          this.$set(k, 'data', this.selectOptionsDisabled(k, result));
        } else {
          this.$set(k, 'data', this.selectOptionsDisabled(k, result.data));
        }
      }
    },

    // 设置下拉框的禁用选项
    selectOptionsDisabled(k, data) {
      if (k.options && k.options.disabledNames) {
        const { disabledNames } = k.options;
        const val = k.options.value || 'dictCode';
        // eslint-disable-next-line no-unused-expressions
        disabledNames
          && data.map((i) => {
            if (disabledNames.includes(i[val])) {
              i.disabled = true;
            } else {
              i.disabled = false;
            }
            return i;
          });
        return data;
      }
      return data;
    },

    // 返回
    goBack(i, formName) {
      if (i.fn) {
        i.fn();
      } else {
        this.resetForm(formName);
        this.$router.back(-1);
      }
    },

    // 初始化输入框提示信息
    initPlaceholder(placeholder, index) {
      if (!placeholder || placeholder.indexOf(':') === -1) {
        return false;
      }
      const keyValue = placeholder.split(':');
      return keyValue[index];
    },

    // 输入信息验证
    inputValidation(enableInput, key) {
      const verificationResult = [];
      if (enableInput) {
        enableInput.forEach((item) => {
          verificationResult.push(publicVar[item].test(this.formData[key]));
        });
        const OXY = verificationResult.some((item) => item === true);
        if (!OXY) {
          if (this.formData[key].length === 1) {
            this.formData[key] = '';
          } else {
            this.formData[key] = this.formData[key].slice(
              0,
              this.OYXInput[key],
            );
          }
        } else {
          this.OYXInput[key] = this.formData[key].length;
        }
      }
    },
    isTrue(arr) {
      console.log(arr);
    },

    // 是否显示表单按钮
    showFormButton() {
      if (this.formConfig.disabled) {
        return false;
      }

      if (
        !this.buttonConfig
        || !this.buttonConfig.buttons
        || this.buttonConfig.buttons.length <= 0
      ) {
        return false;
      }

      return true;
    },

    // 是否显示上传
    showUpload(maxCount, fileCount, disabled) {
      if (this.formConfig.disabled) {
        return false;
      }

      if (disabled) {
        return false;
      }

      if (!maxCount && fileCount < 1) {
        return true;
      }

      if (fileCount < maxCount) {
        return true;
      }

      if (fileCount >= maxCount) {
        return false;
      }
    },

    // 禁用
    itemDisabled(data, show) {
      let copyShow = show;
      if (typeof show === 'string') {
        if (show === 'disabled') {
          return true;
        }
        copyShow = show.replace(/\s+/g, '');
        const params = copyShow.slice(0, show.indexOf('='));
        const params1 = copyShow.slice(show.indexOf('='), show.length);
        // eslint-disable-next-line no-eval
        return eval(`data.${params}${params1}`);
      }
      if (typeof show === 'boolean') {
        return show;
      }
      return false;
    },

    // 下拉框禁用
    isDisabled(data, show) {
      if (typeof show === 'boolean') {
        return show;
      }
      if (show) {
        let copyShow = show;
        copyShow = show.replace(/\s+/g, '');
        const params = copyShow.slice(0, show.indexOf('='));
        const params1 = copyShow.slice(show.indexOf('='), show.length);
        // eslint-disable-next-line no-eval
        return eval(`data.${params}${params1}`);
      }
      return false;
    },

    // 是否显示每一项
    showItem(data, show) {
      if (typeof show === 'boolean') {
        return show;
      }
      if (show) {
        let copyShow = show;
        copyShow = show.replace(/\s+/g, '');
        const params = copyShow.slice(0, show.indexOf('='));
        const params1 = copyShow.slice(show.indexOf('='), show.length);
        // eslint-disable-next-line no-eval
        return eval(`data.${params}${params1}`);
      }
      return true;
    },

    /* --------------地图----------------*/
    showMapFn(data) {
      this.mapConfig.mapDialogShow = true;
      this.mapConfig.saveCallBack = data.fn;
      this.mapConfig.key = data.key;
      this.mapConfig.siteName = this.formData[data.key];
      this.mapConfig.addressLocation = data.addressLocation;
    },

    // 初始化地图
    initMap() {
      this.mapConfig.map = new AMap.Map('map-container', {
        resizeEnable: true,
        zoom: 8,
        viewMode: '2D',
        zooms: [7, 18],
      });
      this.clickOn();
    },

    // map事件绑定
    clickOn() {
      this.mapConfig.map.on('click', this.showInfoClick);
    },

    // map解绑事件
    clickOff() {
      this.mapConfig.map.off('click', this.showInfoClick);
    },

    // 地址逆查询
    searchSiteName() {
      const _this = this;
      AMap.plugin('AMap.Geocoder', () => {
        const geocoder = new AMap.Geocoder({
          city: '0',
        });
        geocoder.getLocation(_this.mapConfig.siteName, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            const position = [
              result.geocodes[0].location.lng,
              result.geocodes[0].location.lat,
            ];
            _this.mapConfig.map.setZoom(13);
            _this.mapConfig.position = position;
            _this.mapConfig.map.setCenter(position);
            _this.addMarker();
          }
        });
      });
    },

    // 坐标查询
    searchPosition() {
      if (this.mapConfig.position.length !== 2) {
        return false;
      }
      const _this = this;
      AMap.plugin('AMap.Geocoder', () => {
        const geocoder = new AMap.Geocoder({
          city: '0',
        });
        const lnglat = _this.mapConfig.position;
        geocoder.getAddress(lnglat, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            _this.mapConfig.siteName = result.regeocode.formattedAddress;
            _this.mapConfig.map.setCenter(lnglat);
            _this.mapConfig.map.setZoom(13);
            _this.addMarker();
          }
        });
      });
    },

    // 点击事件
    showInfoClick(e) {
      this.mapConfig.position = [e.lnglat.lng, e.lnglat.lat];
      this.searchPosition();
    },

    // 添加标记
    addMarker() {
      this.mapConfig.map.clearMap();
      const marker = new AMap.Marker({
        position: new AMap.LngLat(
          this.mapConfig.position[0],
          this.mapConfig.position[1],
        ),
        offset: new AMap.Pixel(-15, -35),
        // icon: require('../../assets/imange/map/position.png'),
      });
      this.mapConfig.map.add(marker);
    },

    // 保存地址
    saveSite() {
      this.formData[this.mapConfig.key] = this.mapConfig.siteName;
      if (this.mapConfig.addressLocation) {
        this.formData[this.mapConfig.addressLocation] = this.mapConfig.position;
      }
      // eslint-disable-next-line prefer-destructuring
      this.formData.longitude = this.mapConfig.position[0];
      // eslint-disable-next-line prefer-destructuring
      this.formData.latitude = this.mapConfig.position[1];
      if (this.mapConfig.saveCallBack) {
        // 回调
        this.mapConfig.saveCallBack({
          siteName: this.mapConfig.siteName,
          location: {
            longitude: this.mapConfig.position[0],
            latitude: this.mapConfig.position[1],
          },
        });
      }
      this.mapConfig = Object.assign(
        this.mapConfig,
        this.$options.data().mapConfig,
      );
    },

    /* -------------------文件上传-----------------------*/
    // 上传验证
    uploadVerification(e, uploadConfig, key, index) {
      const file = e.target.files[0];
      let size = 0;
      const fileType = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length,
      );

      if (uploadConfig) {
        const accept = uploadConfig ? uploadConfig.accept : '';
        // 格式验证
        if (uploadConfig.accept && accept.indexOf(fileType) === -1) {
          this.$message.error('文件类型错误');
          return false;
        }

        // 大小验证
        if (uploadConfig.size) {
          if (
            uploadConfig.size.indexOf('m')
            || uploadConfig.size.indexOf('M')
          ) {
            // eslint-disable-next-line radix
            size = parseInt(uploadConfig.size) * 1024;
          }

          if (uploadConfig.size.indexOf('kb') !== -1) {
            // eslint-disable-next-line radix
            size = parseInt(uploadConfig.size);
          }
          if (file.size / 1024 > size) {
            this.$message.error(`文件大小不能超过${uploadConfig.size}`);
            return false;
          }
        }

        if (
          uploadConfig.count
          && this.formData[key]?.length >= uploadConfig.count
        ) {
          this.$message.error(`只能上传${uploadConfig.count}个文件`);
          return false;
        }
      }

      if (index) {
        this.upDataFileIndex = index;
      }

      this.initiateUploadRequest(uploadConfig, file, key);
      e.target.value = '';
    },

    // 显示图片
    showResources(c) {
      if (c && c.accept) {
        if (
          c.accept.indexOf('video') !== -1
          || c.accept.indexOf('audio') !== -1
        ) {
          return true;
        }
        return false;
      }
      return false;
    },

    // 触发上传
    editUpLoad(index, e) {
      const input = e.currentTarget.previousElementSibling;
      input.click();
      this.upDataFileIndex = index;
    },

    // 上传(用于列表)
    initiateUploadRequest(uploadConfig, file, key) {
      const requestPath = uploadConfig && uploadConfig.uploadPath
        ? uploadConfig.uploadPath
        : API.upload;
      const formData = new FormData();
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
      formData.append('file', file);
      if (uploadConfig && uploadConfig.params) {
        let params = uploadConfig.params.replace(/\s+/g, '');
        params = params.split(':');
        formData.append(params[0], params[1]);
      } else {
        formData.append('materType', '');
      }
      this.uploadLoading = true;
      this.$http.post(requestPath, formData, { headers }).then((res) => {
        // 列表上传数据处理
        if (this.ruleForm[key]) {
          this.ruleForm[key].push(res.result[0]);
        } else {
          this.$set(this.ruleForm, key, res.result);
        }
        this.uploadLoading = false;
      });
    },

    // 显示图片
    showImgDig(imgURL, config) {
      if (this.formConfig.disabled) {
        return false;
      }

      if (config && config.method && !config.method.includes('big')) {
        return false;
      }
      this.dialogVisible = true;
      this.imgURL = imgURL;
    },

    // 删除文件
    deleteFile(index, key) {
      if (this.formConfig.disabled) {
        return false;
      }

      if (this.uploadType === 'list') {
        this.ruleForm[key].splice(index, 1);
      } else {
        this.ruleForm[key] = '';
      }
    },

    /* -----------------------表单验证-------------------*/
    // 提交前
    beforeSubmit(fn, formName, i) {
      if (i) {
        i.loading = true;
      }
      if (this.buttonConfig) {
        const { validateAll } = this.buttonConfig;
        if (validateAll && validateAll.length > 0) {
          // 需要验证多张表单
          this.formsValidate(fn, validateAll, i);
        } else {
          this.submitForm(fn, formName, false, i);
        }
      } else {
        this.submitForm(fn, formName, false, i);
      }
      if (i) {
        i.loading = false;
      }
    },

    // 多张表单验证
    formsValidate(validateAll, fn, i) {
      const validateState = [];
      validateAll.forEach((item) => {
        if (this.$parent.$refs[item]) {
          this.$parent.$refs[item].submitForm('formData', fn, validateState);
        } else {
          this.$parent.$parent.$refs[item].submitForm(
            'formData',
            fn,
            validateState,
          );
        }

        if (validateState.length === validateAll.length) {
          if (validateState.includes(false)) {
            this.$message.error('请填写完所有必填项');
          } else {
            i.loading = true;
            fn(this.ruleForm);
          }
        }
      });
    },

    // 表单验证
    submitForm(formName, fn, state, i) {
      const formRef = formName || 'formData';
      let pass = false;
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          pass = true;
          if (state) {
            state.push(valid);
            return false;
          }

          if (fn) {
            i.loading = true;
            fn(this.formData);
          }
        } else {
          pass = false;
          if (state) {
            state.push(false);
            return false;
          }
          this.$message.error('请填写完所有必填项');
          return false;
        }
      });
      return pass;
    },

    /* ----------------------表单重置---------------------*/
    formsForEach(formName, fn) {
      const { validateAll } = this.buttonConfig;
      if (validateAll && validateAll.length > 0) {
        validateAll.forEach((item) => {
          if (this.$store.state.setThis.$refs[item]) {
            this.$store.state.setThis.$refs[item].resetForm(formName, fn);
          } else {
            this.$parent.$refs[item].resetForm(formName, fn);
          }
        });
        if (fn) {
          fn(this.formData);
        }
      } else {
        this.resetForm(formName, fn);
        if (fn) {
          fn(this.formData);
        }
      }
    },

    // 重置
    resetForm(formName = 'formData', fn) {
      this.$refs[formName].resetFields();
      if (fn) {
        fn();
      }
    },
  },
};
</script>

<style lang="less">
.look_btn{
    margin-left:-130px !important;
    font-size: 12px;
    border-radius: 3px;
    background-color: #c34231;
    color: #fff;
    cursor: pointer;
    border: none;
    height: auto;
    width:auto;-webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    visibility:visible !important
    }
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.public-form {
  .bigImgDialog .el-dialog {
    min-height: 350px;
    height: 350px;
  }

  .el-input {
    display: flex;
  }

  .el-input__inner {
    width: 100%;
  }

  .el-date-editor .el-range-separator {
    width: 10%;
  }

  .el-date-editor.el-input {
    width: 100%;
  }

  .el-input-group__append {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  textarea.el-textarea__inner {
    padding: 10px;
  }

  .addLabelDi alog .el-dialog {
    min-height: 200px;
    height: 300px;
  }

  .upload-box {
    .el-input__inner,
    .el-input {
      height: 24px;
      line-height: 24px;
    }

    .el-input {
      width: 100%;
      margin-top: 5px;
    }
  }
}
</style>
<style scoped lang="less">
@import "form-component";
</style>

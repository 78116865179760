<template>
  <div>

    <div v-if="buttonCode">
      <span  @click="clickEvent()" v-if="btnType === 'default'" :class="['button_default', icon]">  <slot></slot></span>
      <el-button :loading="loading" :type="btnType" :size="size" :disabled="disabled"
                 v-if="curBtn && btnType !== 'default'"
                 @click="clickEvent()"
                 :icon="icon ? icon : ''">
        <slot></slot>
      </el-button>
    </div>
    <div v-else>
      <span  @click="clickEvent()" v-if="btnType === 'default'" :class="['button_default', icon]">  <slot></slot></span>
      <el-button :loading="loading" v-else  :type="btnType" :size="size" :disabled="disabled"
                 @click="clickEvent()"
                 :icon="icon ? icon : ''">
        <slot></slot>
      </el-button>

    </div>
  </div>

</template>

<script>

export default {
  name: 'system_button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    buttonCode: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    btnType: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newDisabled: false,
      curBtn: {
        checked: true,
      },

    };
  },

  methods: {
    clickEvent() {
      this.$emit('clickEvent');
    },
  },
  mounted() {
    // if (this.$route.query.disabled) {
    //   this.disabled = this.$route.query.disabled;
    // }

    // this.curBtn = this.PublicFn.getCanButton(this.$store.state.configMenuList, this.buttonCode)
    // ?this.PublicFn.getCanButton(this.$store.state.configMenuList, this.buttonCode):{checked: true };
  },
};
</script>

<style lang="less" scoped>
div{
  display: inline-block;
}
button.el-button {
  margin-right: 5px;
  display: inline-block;
}

.button_default {
  color: #c34231;
  padding: 0 10px;
  border-right: 1px solid #dcdfe6;
  cursor: pointer;
  &:last-child {
    border-right: 0;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.buttonCode
      ? _c(
          "div",
          [
            _vm.btnType === "default"
              ? _c(
                  "span",
                  {
                    class: ["button_default", _vm.icon],
                    on: {
                      click: function ($event) {
                        return _vm.clickEvent()
                      },
                    },
                  },
                  [_vm._t("default")],
                  2
                )
              : _vm._e(),
            _vm.curBtn && _vm.btnType !== "default"
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      loading: _vm.loading,
                      type: _vm.btnType,
                      size: _vm.size,
                      disabled: _vm.disabled,
                      icon: _vm.icon ? _vm.icon : "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickEvent()
                      },
                    },
                  },
                  [_vm._t("default")],
                  2
                )
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.btnType === "default"
              ? _c(
                  "span",
                  {
                    class: ["button_default", _vm.icon],
                    on: {
                      click: function ($event) {
                        return _vm.clickEvent()
                      },
                    },
                  },
                  [_vm._t("default")],
                  2
                )
              : _c(
                  "el-button",
                  {
                    attrs: {
                      loading: _vm.loading,
                      type: _vm.btnType,
                      size: _vm.size,
                      disabled: _vm.disabled,
                      icon: _vm.icon ? _vm.icon : "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickEvent()
                      },
                    },
                  },
                  [_vm._t("default")],
                  2
                ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const WrapCol = {
  props: {
    value: Object,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  methods: {
    onInput(e) {
      console.log(e);
    },
  },
  render(h, context) {
    return <fragment>
      {/* <el-col>
        <el-form-item prop={'span'} label={'栅格宽度'}>
          <el-input-number min={0} max={24} v-model={this.value.span}></el-input-number>
        </el-form-item>
      </el-col> */}
      <el-col>
        <el-form-item prop={'gutter'} label={'栅格间隔'}>
          <el-input-number min={0} max={24} v-model={this.value.gutter}></el-input-number>
        </el-form-item>
      </el-col>
    </fragment>;
  },
};

export default {
  name: 'formConfig',
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    formConfig: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  render() {
    return <el-form v-model={this.formConfig} size={'mini'} label-position={'left'} label-width={'100px'}>
      <el-row>
        <el-col>
          <el-form-item prop={'size'} label={'表单尺寸'}>
            <el-radio-group v-model={this.formConfig.size}>
              <el-radio-button label="medium"></el-radio-button>
              <el-radio-button label="small"></el-radio-button>
              <el-radio-button label="mini"></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop={'labelPposition'} label={'标签对齐'}>
            <el-radio-group v-model={this.formConfig.labelPposition}>
              <el-radio-button label="right"></el-radio-button>
              <el-radio-button label="left"></el-radio-button>
              <el-radio-button label="top"></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop={'col'} label-width={'0'}>
            <WrapCol v-model={this.formConfig.col}></WrapCol>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop={'modalType'} label={'表单类型'}>
            <el-select v-model={this.formConfig.modalType}>
              <el-option
              key="modal"
              label="弹窗"
              value="modal"/>
              <el-option
              key="drawer"
              label="抽屉"
              value="drawer"/>
          </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop={'controlWidth'} label={'窗口宽度(%)'}>
            <el-slider
              v-model={this.formConfig.windowWidth}
              min={0} max={100}
              step={10}
              show-stops>
            </el-slider>
          </el-form-item>
        </el-col>
        <el-col span={10}>
          <el-form-item prop={'disabled'} label={'是否禁用'}>
            <el-switch v-model={this.formConfig.disabled}></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>;
  },
};

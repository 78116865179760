<template>
  <div class="table-search">
    <el-form ref="searchForm" :model="searchForm" :label-width="searchConfig.labelWidth || '0'">
      <el-form-item v-for="(i, index) in searchConfig.options" :key="index"
                    :style="{marginRight: searchConfig.itemMargin || '20px'}" :label="i.label"
                    :label-width="i.labelWidth">
        <slot></slot>

        <!--输入框-->
        <div @keyup.13="searchData()">
          <el-input v-if="!i.valueType || i.valueType === 'text'"
                    :placeholder="i.placeholder || '请输入'"
                    v-model="searchForm[i.valueName]"></el-input>
        </div>

        <!--下拉框-->
        <el-select clearable v-model="searchForm[i.valueName]" v-if="i.valueType === 'select'"
                   :placeholder="i.placeholder || '请选择'">
          <el-option
            v-for="item in i.data"
            :key="item.value"
            :label="(i.option && item[i.option['label']]) ? item[i.option['label']] : item.dictValue"
            :value="(i.option && item[i.option['value']]) ? item[i.option['value']] : item.dictCode">
          </el-option>
        </el-select>

        <!-- 联级下拉框 -->
        <el-cascader
          v-model="searchForm[i.valueName]" v-if="i.valueType === 'cascader'"
          :placeholder="i.placeholder || '请选择'"
          :show-all-levels="i.config && i.config.showAllLevels ? i.config.showAllLevels : false"
          :props="initCascaderProp(i)"></el-cascader>

        <!--时间日期范围选择器-->
        <el-date-picker
          v-if="i.valueType === 'datetimerange'"
          v-model="searchForm[i.valueName]"
          type="datetimerange"
          value-format="timestamp"
          range-separator="至"
          :start-placeholder="initPlaceholder(i.placeholder, 0) || '开始日期'"
          :end-placeholder="initPlaceholder(i.placeholder, 1) || '结束'">
        </el-date-picker>

        <!--日期选择器-->
        <el-date-picker
          v-if="i.valueType === 'date'"
          v-model="searchForm[i.valueName]"
          type="date"
          value-format="yyyy-MM-dd"
          :placeholder="i.placeholder || '请选择日期'">
        </el-date-picker>

        <!--日期时间选择器-->
        <el-date-picker
          v-if="i.valueType === 'datetime'"
          v-model="searchForm[i.valueName]"
          value-format="timestamp"
          type="datetime"
          :placeholder="i.placeholder || '请选择时间'">
        </el-date-picker>

        <!--日期范围选择器-->
        <el-date-picker
          class="date-range-box"
          v-if="i.valueType === 'daterange'"
          v-model="searchForm[i.valueName]"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          :start-placeholder="initPlaceholder(i.placeholder, 0) || '开始日期'"
          :end-placeholder="initPlaceholder(i.placeholder, 1) || '结束'">
        </el-date-picker>

      </el-form-item>

      <!--按钮-->
      <el-form-item>
        <ButtonComponent :btnType="'primary'" @clickEvent="searchData()" :icon="'el-icon-search'">搜索</ButtonComponent>
        <ButtonComponent @clickEvent="resetForm()" :icon="'el-icon-refresh'">重置</ButtonComponent>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import API from '../../utils/component_serve_api';
import ButtonComponent from '../button-component/button-component';

export default {
  name: 'table-search',
  props: {
    searchConfig: {
      type: Object,
      default: () => ({}),
    },
    searchParams: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ButtonComponent,
  },
  data() {
    return {
      leaf: false,
      props: {
        lazy: true,
        value: 'id',
        label: 'areaName',
        lazyLoad: this.lazyLoad,
      },
      searchForm: {},
    };
  },
  computed: {
  },
  created() {
    this.initSelectData();
  },

  methods: {

    // // 连级查询
    // lazyLoad(node, resolve) {
    //   const { level } = node;
    //   this.$http.get(`${publicAPI.areaListTreeNode}?parentId=${node.data ? node.data.id : 0}`).then((res) => {
    //     if (res.status === 200) {
    //       res.result.map((item) => {
    //         if (this.leaf) {
    //           item.leaf = level >= this.leaf;
    //         }
    //       });
    //       resolve(res.result);
    //     }
    //   });
    // },

    // 初始化联级下拉的配置
    initCascaderProp(c) {
      let props = {
        lazy: true,
        lazyLoad: this.lazyLoad,
        checkStrictly: c.config && c.config.checkStrictly ? c.config.checkStrictly : false,
      };
      this.leaf = c.config && c.config.leaf ? c.config.leaf : false;
      props = { ...props, ...c.option };
      return props;
    },

    // 初始化下拉框的数据
    async initSelectData() {
      for (const i of this.searchConfig.options) {
        if (i.valueType === 'select' && i.path && !i.data) {
          const qs = require('qs');
          const params = qs.parse(i.path.split('?')[1]);
          const urlName = i.path.slice(0, i.path.indexOf('?'));
          // eslint-disable-next-line no-await-in-loop
          const { result } = await this.$http.post(API[urlName], params);
          if (result.constructor === Array) {
            this.$set(i, 'data', result);
          } else {
            this.$set(i, 'data', result.pageList);
          }
        }
      }
    },

    // 初始化输入框提示信息
    initPlaceholder(placeholder, index) {
      if (!placeholder || placeholder.indexOf(':') === -1) {
        return false;
      }
      const keyValue = placeholder.split(':');
      return keyValue[index];
    },

    // 查询
    searchData() {
      this.$emit('search', this.searchForm);
    },

    // 重置
    resetForm() {
      this.searchForm = {};
      this.$emit('search', {});
    },
  },
};
</script>

<style scoped lang="less">
.el-form {
  display: flex;
  flex-wrap: wrap;
}

.el-form-item {
  //display: flex;
  //margin-right: 50px;
  margin-bottom: 10px;
}

.el-button--success{
  background-color: #c34231 !important;
  border-color: #c34231;
}
.el-date-editor--datetimerange.el-input__inner {
  //width: 590px;
  width: 360px;

  .el-range-input {
    text-align: left;
  }
}

.date-range-box.el-input__inner {
  width: 250px;
}

</style>

<style lang="less">
.table-search {
  padding: 5px 0;
  .el-form-item {
    .el-form-item__label {
      width: 100px;
    }
  }
}

</style>

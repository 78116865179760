<template>
  <div class="drawer-container">
    <div class="drawer-content">
      <div v-for="i in drawerConfig" :key="i.title">
        <div class="drawer-content-item">
          <div v-if="i.type === 'form' && i.show !== false">
            <div class="drawer-content-title">{{ i.title }}</div>
            <PublicForm
              ref="publicForm"
              :formConfig="i.formConfig"
              :ruleForm="i.ruleForm"
              :rules="i.rules"
            ></PublicForm>
          </div>
          <div v-if="i.type === 'table' && i.show !== false">
            <div class="drawer-content-title">{{ i.title }}</div>
            <slot :name="i.slotName"></slot>
            <TableComponent
              style="margin-top: 20px"
              :tableButtons="i.tableButtons"
              :templateData="i.tableData"
              :pagination="i.pagination"
              @searchData="searchData"
              :tableConfig="i.tableConfig"
            >
            </TableComponent>
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
    <div class="drawer-button">
      <template v-if="!button || button.show !== false">
        <div v-if="button && button.options">
          <buttonComponent
            v-for="i in button.options"
            @clickEvent="i.fn ? i.fn() : ''"
            :loading="loading"
            :btnType="'primary'"
            :key="i.text"
            :icon="'el-icon-check'"
            >{{ i.text }}
          </buttonComponent>
        </div>
        <buttonComponent
          @clickEvent="beforeSubmit"
          :loading="loading"
          :btnType="'primary'"
          :icon="'el-icon-check'"
          >提交
        </buttonComponent>
        <buttonComponent
          @clickEvent="$emit('colse')"
          :btnType="'danger'"
          :icon="'el-icon-close'"
          >关闭</buttonComponent
        >
      </template>
      <slot name="definedBtn"></slot>
    </div>
  </div>
</template>

<script>
import PublicForm from '../form-component/form-component';
import buttonComponent from '../button-component/button-component';
import TableComponent from '../table-component/table-component.vue';

export default {
  name: 'form-component',
  components: {
    PublicForm,
    buttonComponent,
    TableComponent,
  },
  props: ['drawerConfig', 'button', 'loading'],
  data() {
    return {
    };
  },
  created() {
    console.log('drawer ----');
  },
  methods: {
    searchData(val) {
      this.$emit('searchData', val);
    },
    resetForm() {
      this.$refs.publicForm[0].resetForm();
    },
    beforeSubmit() {
      const { publicForm } = this.$refs;
      const pass = [];
      publicForm.forEach((i) => {
        pass.push(i.submitForm());
      });

      const params = {
        ...this.$refs.publicForm[0].formData,
      };
      if (pass.every((i) => i)) {
        this.$emit('save', params);
      }
    },
  },
};
</script>

<style scoped lang="less">
.drawer-container {
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;

  .drawer-content {
    height: 100%;
    margin-bottom: 73px;
  }

  .drawer-content-title {
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    padding: 20px 0;
  }

  .drawer-content-item {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border: none;
    }
  }

  .drawer-button {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    z-index: 99;
    background-color: #fff;
    border-top: 1px solid #e4e4e4;
  }
}
</style>

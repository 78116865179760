<template>
  <div class="components-tabel">
    <TableSearch  v-if="searchConfig" style="margin-bottom:20px" :search-config="searchConfig" @search="searchData"></TableSearch>
    <div :style="{width: width || '100%'}" v-loading="tableLoading" element-loading-text="加载中...">
      <div class="tabel-button-box" style="line-height: 0">
        <div class="button-container" style="display: inline-block">
          <slot style="margin-bottom: 10px;"></slot>
        </div>

        <div style="display:inline-block;margin-bottom: 10px;" v-if="tableButtons && tableButtons.length > 0 && this.tableConfig.disabled !== true">
          <div class="button-container" style="display:inline-block;" v-for="(i, index) in tableButtons" :key="index">
            <BUTTON
              v-if="(!i.type || i.type === 'add') && i.show !== false"
              :buttonCode="i.level ? '' : 'ADD'"
              :disabled="i.disabled"
              @clickEvent="i.fn()">
              {{ i.title ? i.title : '新增'}}
            </BUTTON>

            <!-- 移动 -->
            <BUTTON
              v-if="i.type === 'move'"
              :buttonCode="'MOVE'"
              :disabled="selectedData.length == 0 ? true : false"
              @clickEvent="i.fn()">
              {{ i.title }}
            </BUTTON>

            <!-- 排序 -->
            <BUTTON
              :buttonCode="i.level?'':'SORT'"
              v-if="i.type === 'sort'"
              @clickEvent="showSortInput(i)">
              {{ inputSort ? '保存排序' : '排序' }}
            </BUTTON>

            <!-- 排序 -->
            <BUTTON
              v-if="i.type === 'loc'"
              :buttonCode="'LOC'"
              @clickEvent="showLocInput(i)">
              {{ inputLoc ? '保存位置' : '位置' }}
            </BUTTON>

            <!-- 删除 -->
            <BUTTON
              :buttonCode="i.level?'':'DEL'"
              v-if="i.type === 'delete'"

              :loading="i.loading"
              :disabled="selectedData.length <= 0 ? true : false"
              @clickEvent="batchData(i)">
              批量删除
            </BUTTON>

            <!-- 推荐 -->
            <BUTTON
              :buttonCode="i.level?'':'RECOMMEND'"
              v-if="i.type === 'recommend'"

              :disabled="selectedData.length <= 0 ? true : false"
              @clickEvent="batchData(i)">
              推荐
            </BUTTON>

            <!-- 取消推荐 -->
            <BUTTON
              :buttonCode="i.level?'':'RECOMMEND'"
              v-if="i.type === 'cancelRecommend'"
              :disabled="selectedData.length <= 0 ? true : false"
              @clickEvent="batchData(i)">
              取消推荐
            </BUTTON>

            <!-- 置顶 -->
            <BUTTON
              :buttonCode="i.level?'':'TOPPING'"
              v-if="i.type === 'top'"
              :disabled="selectedData.length <= 0 ? true : false"
              @clickEvent="batchData(i)">
              置顶
            </BUTTON>

            <!-- 取消置顶 -->
            <BUTTON
              v-if="i.type === 'cancelTop'"
              :btnType="'success'"
              :buttonCode="'TOPPING'"
              :disabled="selectedData.length <= 0 ? true : false">取消置顶
            </BUTTON>

            <!-- 上传 -->
            <div class="button-box" v-if="i.type === 'upload'">
              <input type="file" @change="upload($event, i.path, i.paramsName, i.fn, i.accept, i.hint)"
                     :accept="i.accept">
              <BUTTON :buttonCode="'IMPORT'" :iconName="'el-icon-upload2'">导入</BUTTON>
            </div>

            <!-- 取消置顶 -->
            <BUTTON
              v-if="i.type === 'share'"
              :btnType="'success'"
              :buttonCode="i.level?'':'SHARE'"
              :disabled="selectedData.length <= 0 ? true : false"
              @clickEvent="batchData(i)">分享
            </BUTTON>

            <!-- 取消置顶 -->
            <BUTTON
              v-if="i.type === 'cancelShare'"
              :btnType="'success'"
              :buttonCode="i.level?'':'SHARE'"
              :disabled="selectedData.length <= 0 ? true : false"
              @clickEvent="batchData(i)">取消分享
            </BUTTON>
            <!-- 下载 -->
            <!--                    <a :href="i.path" v-if="i.type === 'download'" download="">-->
            <!--                        <BUTTON :buttonCode="'EXPORT'" @click="derive(i.path)"-->
            <!--                                :iconName="'el-icon-download'"-->
            <!--                                v-if="i.type === 'download'">-->
            <!--                            导出-->
            <!--                        </BUTTON>-->
            <!--                    </a>-->
            <BUTTON v-if="i.type === 'download'" :buttonCode="'EXPORT'" @clickEvent="derive(i.path)"
                    :iconName="'el-icon-download'">导出
            </BUTTON>

            <!-- 下载Blod文件 -->
            <BUTTON
              v-if="i.type === 'downloadBlod'"
              :buttonCode="'EXPORT'"
              :iconName="'el-icon-download'"
              @clickEvent="downLoad(i.path, i.fn, i.hint, i.fileName)">
              导出
            </BUTTON>
          </div>
        </div>

      </div>
      <el-table
        :stripe="true"
        ref="multipleTable"
        tooltip-effect="dark"
        :show-overflow-tooltip="true"
        style="width: 100%"
        :border="true"
         max-height="400"
        :header-cell-style="{backgroundColor: '#e8e8e8'}"
        :data="templateData"
        :span-method="tableConfig.objectSpanMethod"
        @selection-change="handleSelectionChange">
        <el-table-column
          v-if="tableConfig.checkbox"
          key="1"
          fixed
          type="selection">
        </el-table-column>
        <el-table-column
          label="序号"
          key="2"
          v-if="tableConfig.index"
          width="70px">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <template v-for="(i) in tableConfig.rows">
          <el-table-column
            :width="i.width"
            :key="i.prop"
            :prop="i.prop"
            :fixed="i.fixed"
            v-if="i.show !== false"
            :label="i.label">
            <template slot-scope="scope">
              <div v-if="(i.type === 'switch') && showButton(scope.row, i.show)">
              <span style="display:inline-block;width: 50px;"
                    :style="{color: scope.row[i.prop] ? 'rgb(19, 206, 102)' : '#cecece', marginRight: '10px'}">{{ scope.row[i.prop] ? "启用" : "禁用"
                }}</span>
                <el-switch
                  :disabled="isDisabled(scope.row, i.disabled)"
                  @change="i.fn ? i.fn(scope.row[i.prop], scope.row) : ''"
                  v-model="scope.row[i.prop]"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </div>
              <div v-if="(i.type === 'sort') && showButton(scope.row, i.show)">
                <div :style="{color: i.color}" v-if="!inputSort" v-html="scope.row[i.prop]"></div>
                <el-input
                  v-else
                  :disabled="isDisabled(scope.row, i.disabled)"
                  @change="setSort(scope.row)"
                  maxLength="3"
                  @input="validateNum(scope.row, i.prop)"
                  v-model="scope.row[i.prop]"
                  style="width: 100%;text-align: center"></el-input>
              </div>
              <div v-if="(i.type === 'locationTag') && showButton(scope.row, i.show)">

                <div :style="{color: i.color}" v-if="!inputLoc" v-html="scope.row[i.prop]"></div>
                <el-input
                  v-else
                  :disabled="isDisabled(scope.row, i.disabled)"
                  @change="setLoc(scope.row)"
                  maxLength="3"
                  @input="validateNum(scope.row, i.prop)"
                  v-model="scope.row[i.prop]"
                  style="width: 100%;text-align: center"></el-input>
              </div>
              <div v-if="(i.type === 'input') && showButton(scope.row, i.show)">
                <el-input
                  :disabled="isDisabled(scope.row, i.disabled)"
                  style="width: 100%;text-align: center" :placeholder="i.placeholder || '请输入'"
                  :type="i.inputType || 'text'"
                  v-model="scope.row[i.prop]"
                  @change="inputVerify(scope.row, i, 'change')"
                  @input="inputVerify(scope.row, i)"></el-input>
              </div>
              <div v-if="(i.type === 'select') && showButton(scope.row, i.show)">
                <el-select :disabled="isDisabled(scope.row, i.disabled)" clearable v-model="scope.row[i.prop]"
                           @change="i.fn ? i.fn(scope.row, scope.row[i.prop]) : ''" :placeholder="i.placeholder || '请选择'">
                  <el-option
                    v-for="item in i.data"
                    :key="item.value"
                    :label="(i.option && item[i.option['label']]) ? item[i.option['label']] : item.dictValue"
                    :value="(i.option && item[i.option['value']]) ? item[i.option['value']] : item.dictCode">
                  </el-option>
                </el-select>
              </div>
              <div v-if="(i.type === 'saleArea') && showButton(scope.row, i.show)">
                <el-cascader
                :options="treeList"
                :props="i.props"
                collapse-tags
                clearable
                :disabled="isDisabled(scope.row, i.disabled)"
                v-model="scope.row[i.prop]"
                @change="i.fn ? i.fn(scope.row, scope.row[i.prop]) : ''"
                :placeholder="i.placeholder || '请选择'"
                >
                </el-cascader>
              </div>
              <div :style="{color: i.color}"
                   v-else-if="(!i.type || i.type === 'text') && showButton(scope.row, i.show)"
                   :class="i.fn ? 'toblue' : ''" @click="getRowData(i, scope, $event)"
                   v-html="i.template || scope.row[i.prop]">
              </div>
            </template>
          </el-table-column>
        </template>

        <el-table-column
          fixed="right"
          v-if="tableShowButtons()"
          :width="tableConfig.rowButtons && tableConfig.rowButtons.width"
          label="操作">
          <template slot-scope="scope">
            <div>
              <div style="display:inline-block;" v-for="(i, index) in (tableConfig.rowButtons && tableConfig.rowButtons.items)"
                   :key="index">
                <div style="display:inline-block;">
                  <BUTTON style="margin-right:5px;"
                          v-if="showButton(scope.row, i.show)"
                          :disabled="isDisabledTabelButton(scope.row, i.disabled)"
                          @clickEvent="i.fn(scope.row, scope.$index)"
                          :btnType="i.type"
                          :icon="i.icon"
                          size="mini"
                          :buttonCode="i.code">
                      {{ i.label }}
                  </BUTTON>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :style="{textAlign: pagination.align || 'right', marginTop: '10px', marginRight: '50px'}"
        v-if="(pagination) && pagination.show !== false"
        @size-change="sizeChange"
        @current-change="pageChange"
        :current-page="pagination.pageNum || searchParams.pageNum"
        :page-sizes="pagination.pageSizes || [10, 20, 30]"
        :page-size="pagination.pageSize || searchParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total || 0">
      </el-pagination>
    </div>
  </div>

</template>

<script>
import API from '@/src/views/dms/utils/component_serve_api';
import BUTTON from '../button-component/button-component';
import TableSearch from '../table-search-component/table-search-component';

export default {
  name: 'table-component',
  components: {
    BUTTON,
    TableSearch,
  },
  props: ['tableButtons', 'searchConfig', 'pagination', 'templateData', 'tableData', 'tableConfig', 'width', 'tableLoading'],
  data() {
    return {
      checkList: [],
      inputSort: false,
      inputLoc: false,
      locSelected: [],
      sortSelected: [],
      selectedData: [],
      selectIds: [],
      searchParams: {
        pageSize: 10,
        pageNum: 1,
      },
      treeList: [],
    };
  },
  watch: {
    templateData(val) {
      if (this.tableConfig.isSaveSelect) {
        val.forEach((row) => {
          if (this.selectIds.includes(row.id)) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(row, true);
            });
          }
        });
      }
    },
  },
  mounted() {
    this.initSelectData();
  },
  created() {
    this.getSaleAreaTreeList();
  },
  beforeDestroy() {
    this.selectIds = [];
    this.selectedData = [];
  },
  methods: {
    // 查询
    async searchData(data) {
      if (this.pagination) {
        this.pagination.pageNum = data?.pageNum || 1;
      }
      this.searchParams.pageNum = this.pagination?.pageNum;
      let searchParams = { ...this.searchParams, ...data };
      if (JSON.stringify(data) === '{}') {
        searchParams = this.$options.data().searchParams;
      }
      this.searchParams = searchParams;
      this.$emit('searchData', searchParams);
    },

    sizeChange(pageSize) {
      this.searchParams.pageSize = pageSize;
      this.searchData({ ...this.searchParams, pageSize });
    },

    pageChange(pageNum) {
      this.pagination.pageNum = pageNum;
      this.searchData({ ...this.searchParams, pageNum });
    },

    // 初始化下拉框的数据
    async initSelectData() {
      for (const i of this.tableConfig.rows) {
        if (i.type === 'select' && i.path && !i.data) {
          const qs = require('qs');
          const params = qs.parse(i.path.split('?')[1]);
          const urlName = i.path.slice(0, i.path.indexOf('?'));
          // eslint-disable-next-line no-await-in-loop
          const { result } = await this.$http.post(API[urlName], params);

          if (result.constructor === Array) {
            this.$set(i, 'data', result);
          } else {
            this.$set(i, 'data', result.pageList);
          }
        }
      }
    },

    // 输入验证
    inputVerify(row, options, type) {
      const count = row[options.prop];
      if (options.verify && !options.verify.test(count)) {
        this.$message.warning(options.verifyInfo);
        return;
      }

      if (options.fn) {
        options.fn(row);
      }
      if (type === 'change' && options.change) {
        options.change(row);
      }
    },

    /* -------------排序------------------------*/
    // 修改排序
    setSort(row) {
      this.sortSelected.push(row);
    },

    // 修改修改位置
    setLoc(row) {
      this.locSelected.push(row);
    },
    // 排序输入验证
    validateNum(data, val) {
      data[val] = data[val].replace(/[^0-9]/ig, '');
    },

    // 排序按钮
    showSortInput(config) {
      this.inputSort = !this.inputSort;
      if (this.inputSort) {
        this.sortSelected = [];
      }

      if (!this.inputSort) {
        if (this.sortSelected.length <= 0) {
          return false;
        }
        let params;
        if (config.dataFormat) {
          params = this.sortDataFormat(config.dataFormat);
        } else {
          params = this.sortParamsName(config.paramsName);
        }

        this.open('warning', config, params);
      }
    },
    // 排序按钮
    showLocInput(config) {
      this.inputLoc = !this.inputLoc;
      if (this.inputLoc) {
        this.locSelected = [];
      }

      if (!this.inputLoc) {
        if (this.locSelected.length <= 0) {
          return false;
        }
        let params;
        if (config.dataFormat) {
          params = this.sortDataFormat(config.dataFormat);
        } else {
          params = this.locParamsName(config.paramsName);
        }

        this.open('warning', config, params);
      }
    },

    // 自定义数据格式解析
    sortDataFormat(dataFormat) {
      const data = JSON.parse(JSON.stringify(dataFormat));
      const foramt = dataFormat.object[0];
      // const dataTypeIndex = foramt.indexOf(':')
      // const dataType = foramt.slice(1, dataTypeIndex)

      const paramsStrIndex = foramt.indexOf('[');
      const paramsStr = foramt.slice(paramsStrIndex + 1, foramt.length - 2);

      data.object = this.sortParamsName(paramsStr);
      return data;
    },

    // ParamsName 数据解析
    sortParamsName(paramsName) {
      let paramsNameCopy = paramsName;
      paramsNameCopy = paramsNameCopy.replace(/\s+/g, ''); // 去空格
      const paramsArray = paramsNameCopy.split(','); // 获取自定义参数的key value名称
      const params = []; // 自定义参数
      this.sortSelected.forEach((obj, index) => {
        params.push({});
        paramsArray.forEach((item) => {
          const keyValue = item.split(':');
          params[index][keyValue[0]] = obj[keyValue[1]];
        });
      });
      return params;
    },

    locParamsName(paramsName) {
      let paramsNameCopy = paramsName;
      paramsNameCopy = paramsNameCopy.replace(/\s+/g, ''); // 去空格
      const paramsArray = paramsNameCopy.split(','); // 获取自定义参数的key value名称
      const params = []; // 自定义参数
      this.locSelected.forEach((obj, index) => {
        params.push({});
        paramsArray.forEach((item) => {
          const keyValue = item.split(':');
          params[index][keyValue[0]] = obj[keyValue[1]];
        });
      });
      return params;
    },
    /* --------------批量操作-----------------------*/
    batchData(config) {
      if (this.selectedData.length <= 0) {
        this.$message.info('提示：请选中一条数据进行修改');
        return;
      }

      const paramsName = config.paramsName.replace(/\s+/g, ''); // 去空格
      const keyValue = paramsName.split(':'); // 获取自定义参数的key value名称

      let params = { // 自定义参数
        [keyValue[0]]: [],
      };
      this.selectedData.forEach((item) => {
        params[keyValue[0]].push(item[keyValue[1]]);
      });
      let { path } = config;
      const qs = require('qs');
      let urlSearch = ''; // 固定参数
      let urlData;
      if (path.indexOf('?') !== -1) {
        urlSearch = path.slice(path.indexOf('?') + 1, path.length);
        path = path.slice(0, path.indexOf('?'));
        urlData = qs.parse(urlSearch, { ignoreQueryPrefix: true });
        for (const i in urlData) { // 转换为boolean
          if (urlData[i] === 'true' || urlData[i] === 'false') {
            urlData[i] = urlData[i] === 'true';
          }
        }
      }
      params = { ...params, ...urlData };
      this.open('warning', config, params);
    },

    /* --------------------- 批量提交 -----------------*/
    open(type, config, params) {
      this.$confirm(config.hintMessage || '确认进行该操作嘛？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type,
      })
        .then(() => {
          this.$http.post(config.path, params)
            .then((res) => {
              this.selectedData = [];
              this.$message.success(res.msg || '操作成功');
              if (config.fn) {
                config.fn();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },

    /* --------------------上传-----------------------*/
    upload(e, path, fn, accept, hint) {
      const file = e.target.files[0];

      if (accept.indexOf(file.type) === -1) {
        this.$message.error('请上传正确的格式');
        return false;
      }

      const form = new FormData();
      form.append('file', file);

      this.$http.post(path, form)
        .then((res) => {
          e.target.value = '';
          this.$message.success(res.msg || hint || '上传成功');
          if (fn) {
            fn();
          }
        });
    },
    derive(path) {
      window.open(path);
    }, /* -----------------------下载-------------------*/
    downLoad(path, fn, hint, fileName) {
      const qs = require('qs');
      let urlSearch = ''; // 固定参数
      let requestPath = path; // 请求路径
      let urlData = {};
      if (requestPath.indexOf('?') !== -1) {
        urlSearch = requestPath.slice(requestPath.indexOf('?') + 1, requestPath.length);
        requestPath = requestPath.slice(0, requestPath.indexOf('?'));
        urlData = qs.parse(urlSearch, { ignoreQueryPrefix: true });
        for (const i in urlData) { // 转换为boolean
          if (urlData[i] === 'true' || urlData[i] === 'false') {
            urlData[i] = urlData[i] === 'true';
          }
        }
      }

      this.$http.post(requestPath, urlData, { responseType: 'arraybuffer' })
        .then((res) => {
          const { data } = res;
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName || '导出.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success(res.msg || hint || '下载成功');

          if (fn) {
            fn();
          }
        });
    },

    /* ---------------------回调---------------------*/
    // 列表选中状态变化时
    handleSelectionChange(selectedRows) {
      if (this.tableConfig.isSaveSelect) { // 保存已选中的项
        const selectRowsId = selectedRows.reduce((item, next) => { // 获取当前选中行的id
          item.push(next.id);
          return item;
        }, []);
        this.templateData.forEach((row) => {
          if (selectRowsId.includes(row.id) && !this.selectIds.includes(row.id)) { // 选中并且未保存
            this.selectedData.push(row);
          }
          if (!selectRowsId.includes(row.id) && this.selectIds.includes(row.id)) { // 取消选中 删除数据
            this.selectedData = this.selectedData.filter((item) => item.id !== row.id);
          }
        });
      } else {
        this.selectedData = selectedRows;
      }
      this.selectIds = [];
      this.selectedData.forEach((item) => this.selectIds.push(item.id));
      this.tableConfig.checkboxFn(this.selectedData);
    },

    // 具有函数的td，点击回调
    getRowData(i, scope, e) {
      const { path } = e;
      if (i.fn) {
        i.fn(scope.row, scope.$index);
      }
      path.forEach((item) => {
        if (item.getAttribute && item.getAttribute('id')) {
          const id = item.getAttribute('id');
          if (i.clickDom) {
            i.clickDom.forEach((obj) => {
              if (obj.id === id && obj.fn) {
                const { tagName } = item;
                const TextDom = ['SPAN', 'DIV', 'P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
                const mediaDom = ['IMG', 'VIDEO', 'AUDEO'];
                const callbackArguments = {
                  tagName,
                };
                if (mediaDom.includes(tagName)) {
                  callbackArguments[id] = item.getAttribute('src');
                }

                if (TextDom.includes(tagName)) {
                  callbackArguments[id] = item.innerText;
                }

                obj.fn(callbackArguments, scope.row);
              }
            });
          }
        }
      });
    },

    /* -------------------渲染规则---------------------*/
    // 操作栏按钮渲染
    tableShowButtons() {
      const { tableConfig } = this;
      let show = true;

      if (tableConfig.disabled === true) {
        show = false;
      }

      if (!tableConfig.rowButtons) {
        show = false;
      }

      if (tableConfig.rowButtons?.show === true) {
        show = true;
      }

      if (show && tableConfig.rowButtons?.items?.length > 0) {
        show = true;
      }

      return show;
    },

    // 是否显示表单button - 单个
    showButton(data, show) {
      if (show && typeof show !== 'boolean') {
        let showCopy = show;
        showCopy = showCopy.replace(/\s+/g, '');
        const params = showCopy.slice(0, showCopy.indexOf('='));
        const params1 = showCopy.slice(showCopy.indexOf('='), showCopy.length);
        // eslint-disable-next-line no-eval
        return eval(`data.${params}${params1}`);
      }
      if (this.tableConfig.disabled) {
        return this.tableConfig.disabled;
      }
      return true;
    },

    // 是否禁用表单button
    isDisabledTabelButton(data, disable) {
      if (typeof disable === 'undefined') {
        return false;
      }

      if (typeof disable === 'boolean') {
        return disable;
      }
      let disableCopy = disable;
      disableCopy = disableCopy.replace(/\s+/g, '');
      const params = disableCopy.slice(0, disableCopy.indexOf('='));
      const params1 = disableCopy.slice(disableCopy.indexOf('='), disable.length);
      // eslint-disable-next-line no-eval
      return eval(`data.${params}${params1}`);
    },

    // 是否禁止全局button
    isDisabled(data, show) {
      if (typeof show === 'boolean') {
        return show;
      }
      if (show) {
        let showCopy = show;
        showCopy = showCopy.replace(/\s+/g, '');
        const params = showCopy.slice(0, showCopy.indexOf('='));
        const params1 = showCopy.slice(showCopy.indexOf('='), show.length);
        // eslint-disable-next-line no-eval
        return eval(`data.${params}${params1}`);
      }
      if (this.tableConfig.disabled) {
        return this.tableConfig.disabled;
      }
      return false;
    },

    // 行政区域树数据
    getSaleAreaTreeList() {
      this.$http.post(
        '/mdm/mdmRegionController/treeList',
      ).then((res) => {
        if (res.success) {
          this.treeList = res.result.data || [];
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "table-component.less";
</style>

<style lang="less">
.components-tabel {
  input {
    text-align: center;
  }

  div.el-input, div .el-select {
    width: 100%;
  }

  .toRed {
    color: red;
  }
}
.table-search {
  input {
    text-align: left;
  }
}
</style>

export default {
  code: '/mdm/mdmdictdata/list', // 字典表
  companyPageList: '/mdm/companyInfo/companyPageList', // 公司主体
  customer: '/mdm/mdmCustomerMsgController/pageList', // 客户
  upload: '/upload/uploadController/uploadOssList', // 上传
  docking: '/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCode', // 负责人
  pullDownContractList: '/dms/dms/contract/pullDownContractList', // 主合同列表
  bankPageList: '/mdm/bankInfo/bankPageList', // 收款账户
  companyCustomer: '/mdm/mdmUserController/findUserSelectList', // 企业用户下拉框
  activity: '/tpm/tpmActExt/actDetail', // 关联活动
};
